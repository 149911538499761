<template>
  <v-container id="error-view" class="fill-height text-center" tag="section">
    <v-row justify="center">
      <v-col cols="auto">
        <h1 class="text-h1 font-weight-bold">404</h1>

        <div class="text-h4 pb-15">Page not found</div>

        <v-btn depressed large to="/"> Back to the homepage </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default { name: "ErrorView" };
</script>

<style lang="sass">
#error-view h1.text-h1
  font-size: 14rem !important
</style>
